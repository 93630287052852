<template>
  <!-- 
    Used to unlock a per default hidden password and copy it to Clipboard
   -->
  <div>
    <span 
      v-show="hidden" 
      class="copyToClipboard"
      @click="peakId ? unlockPasswordPeak() : unlockPassword()"
    >
      <font-awesome-icon
        class="mr-1"
        icon="lock-open"
      />
    </span>
    <span
      class="copyToClipboard"
      @click="peakId ? getPeakPw($event) : copyToClipboard(rPassword, $event)"
    >
      <font-awesome-icon
        class="mr-1"
        icon="copy"
      />
      <template v-if="hidden"> *********</template>
      <template v-else><var>{{ rPassword }}</var></template>
    </span>
  </div>
</template>

<script>
export default {
  name: "Password",
  props: {
    password: {
      type: String,
      required: true
    },
    hide: {
      type: Boolean,
      required: false,
      default: false
    },
    peakId: {
      type: Number,
      required: false,
      default: null
    },
    id: {
      type: Number,
      required: false,
      default: null
    },
    installationId: {
      type: String,
      required: false,
      default: null
    }
  },
  data: function () {
  return {
      rPassword: null,
      hidden: { 
        type: Boolean,
        required: false,
        default () {
          return true;
        }
      }
    };
  },
  watch: {
    id () {
      this.rPassword = this.password
      if(this.hide) {
        this.hidden = false;
      }
      else {
        this.hidden = true;
      }
    }
  },
  created () {
    this.rPassword = this.password;
    if(this.hide) {
      this.hidden = false;
    }
  },
  methods: {
    // copies the specified data to the clipboard of the user
    getPeakPw (event) {
      this.axios.get(`/SoftwareComponent/GetPeakPw?id=${this.peakId}&installationId=${this.installationId}`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.rPassword = response.data;
        this.copyToClipboard(this.rPassword, event);
      })
    },
    copyToClipboard (data, event) {
      this.$emit('sawPw');
      let target = event.target;
      
      if(target.nodeName == "svg") {
        return;
      }
      let targetClass = event.target.getAttribute('class');
      targetClass = 'copyToClipboard animated';
      target.setAttribute('class', targetClass);
      setTimeout(() => {
        targetClass = 'copyToClipboard';
        target.setAttribute('class', targetClass);
      }, 333);
      // adds an element to hold the data
      let element = document.createElement('textarea');
      // passes the data to the newly created element
      element.value = data;
      element.setAttribute('readonly', '');
      element.className = 'offScreen';
      // adds the element to the website (offscreen)
      document.body.appendChild(element);
      element.select();
      // copies the content of the element to the clipboard
      document.execCommand('copy');
      // removes the created element now that it's no longer of use
      document.body.removeChild(element); 
      // inform the user about the success of the function
      this.$snotify.success("The data has been copied to your clipboard.");
    },
    unlockPassword () {
      this.$emit('sawPw');
      this.hidden = false;
    },
    unlockPasswordPeak () {
      this.axios.get(`/SoftwareComponent/GetPeakPw?id=${this.peakId}&installationId=${this.installationId}`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.rPassword = response.data;
        this.$emit('sawPw');
        this.hidden = false;
      })
    }
  }
}
</script>

<style scoped>
.copyToClipboard,
.copyToClipboard i {
  cursor: pointer;
  font-size: 1rem;
}
.copyToClipboard:hover,
.copyToClipboard:hover i::before {
  color: #177ba8;
  font-size: 1rem;
}
.copyToClipboard.animated {
  animation-name: bounce;
  animation-duration: .333s;
  font-size: 1rem;
}
@keyframes bounce {
  0% {font-size: 1rem;}
  50% {font-size: 0.5rem;}
  100% {font-size: 1rem;}
}
</style>
